<template>
    <o-data-lookup :data-object="dsLookupDataObject">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="300"></o-column>
        <o-column field="StartDate" width="120"></o-column>
        <o-column field="FinishDate" width="120"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { getOrCreateDataObject } from 'o365-dataobject';

    const props = defineProps({
        is: String,
        textInputValue: String,
        textInput: Boolean
    });


    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'sviw_System_CalendarsLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string"},             
             {name: "StartDate", type: "date", sortOrder: 1, sortDirection: "asc"  },      
             {name: "FinishDate", type: "date" }]
    }); 
    
     
</script>